import React, { ReactNode, useState } from 'react'
import Popover from '@howdy/core/elements/PopOver'

const Tooltip = ({
  tooltipContent,
  position = 'top',
  className = '',
  children,
  hide = false,
}: {
  tooltipContent: any
  position?: string
  className?: string
  children: ReactNode
  hide?: boolean
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const show = showTooltip && !hide

  return (
    <Popover isVisible={show} content={tooltipContent} position={position} classes={className}>
      <div
        onMouseEnter={() => {
          setShowTooltip(true)
        }}
        onMouseLeave={() => {
          setShowTooltip(false)
        }}
        onTouchStart={() => {
          setShowTooltip(true)
        }}
      >
        {children}
      </div>
    </Popover>
  )
}

export default Tooltip
