'use client'

import { ErrorInfo, FC, ReactNode } from 'react'
import {
  ErrorBoundaryPropsWithComponent,
  ErrorBoundaryPropsWithFallback,
  ErrorBoundaryPropsWithRender,
  ErrorBoundaryProps as ReactErrorBoundaryProps,
  ErrorBoundary as ReactErrorBoundary,
} from 'react-error-boundary'
import * as Sentry from '@sentry/nextjs'

import DefaultFallbackComponent from './DefaultFallbackComponent'

interface BaseProps
  extends Omit<ReactErrorBoundaryProps, 'fallback' | 'fallbackRender' | 'FallbackComponent'> {
  children: ReactNode
}

interface DefaultFallbackProps {
  fallback?: never
  fallbackRender?: never
  FallbackComponent?: never
}

interface GeneralFallbackProps {
  fallback: ErrorBoundaryPropsWithFallback['fallback']
  fallbackRender?: never
  FallbackComponent?: never
}

interface RendererFallbackProps {
  fallback?: never
  fallbackRender: ErrorBoundaryPropsWithRender['fallbackRender']
  FallbackComponent?: never
}

interface ComponentFallbackProps {
  fallback?: never
  fallbackRender?: never
  FallbackComponent: ErrorBoundaryPropsWithComponent['FallbackComponent']
}

export type ErrorBoundaryProps = BaseProps &
  (DefaultFallbackProps | GeneralFallbackProps | RendererFallbackProps | ComponentFallbackProps)

const ErrorBoundary: FC<ErrorBoundaryProps> = ({
  onError,
  children,
  fallback,
  fallbackRender,
  FallbackComponent,
  ...restProps
}) => {
  let fallbackProps: GeneralFallbackProps | RendererFallbackProps | ComponentFallbackProps = {
    FallbackComponent: DefaultFallbackComponent,
  }

  // Only one of the fallback options can be applied
  // If none is provided, the DefaultFallbackComponent is rendered instead
  if (fallback) {
    fallbackProps = { fallback }
  } else if (fallbackRender) {
    fallbackProps = { fallbackRender }
  } else if (FallbackComponent) {
    fallbackProps = { FallbackComponent }
  }

  const handleError = (error: Error, info: ErrorInfo) => {
    Sentry.captureException(error, {
      extra: {
        info,
      },
      tags: {
        source: 'error_boundary',
      },
    })
    onError?.(error, info)
  }

  const errorBoundaryProps: ReactErrorBoundaryProps = {
    onError: handleError,
    ...fallbackProps,
    ...restProps,
  }

  return <ReactErrorBoundary {...errorBoundaryProps}>{children}</ReactErrorBoundary>
}

export default ErrorBoundary
