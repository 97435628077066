export const getNavigatableLink = (link) => {
  return link?.includes('://') ? link : `//${link}`
}

import clsx, { ClassValue } from 'clsx'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { useEventCallback, useEventListener } from 'usehooks-ts'
import { successToast } from './toast'
import isNil from 'lodash/isNil'

declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent
  }
}

type SetValue<T> = Dispatch<SetStateAction<T>>

export function useLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = useCallback((): T => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      const item = window.localStorage.getItem(key)
      return item ? (parseJSON(item) as T) : initialValue
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error)
      return initialValue
    }
  }, [initialValue, key])

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue)

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue<T> = useEventCallback((value) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`,
      )
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(newValue))

      // Save state
      setStoredValue(newValue)

      // We dispatch a custom event so every useLocalStorage hook are notified
      window.dispatchEvent(new Event('local-storage'))
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error)
    }
  })

  useEffect(() => {
    setStoredValue(readValue())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return
      }
      setStoredValue(readValue())
    },
    [key, readValue],
  )

  // this only works for other documents, not the current one
  useEventListener('storage', handleStorageChange)

  // this is a custom event, triggered in writeValueToLocalStorage
  // See: useLocalStorage()
  useEventListener('local-storage', handleStorageChange)

  return [storedValue, setValue]
}

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    console.log('parsing error on', { value })
    return undefined
  }
}

export function isEmpty(value?: any): boolean {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export function getFirstLetters(firstName: string, lastName: string = '') {
  return `${firstName} ${lastName}`
    .trim()
    .split(' ')
    .map((w) => w[0])
    .slice(0, 2)
    .join('')
}

export function validateEmail(email: string) {
  // Regular expression pattern for email validation
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  // Check if the email matches the pattern
  return re.test(email)
}

export const formatValue = (value: number | string) => {
  const _value = typeof value == 'string' ? parseInt(value) : value
  return _value.toLocaleString('en-US')
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function truncateString(str: string, maxLength: number = 30) {
  if (!str) return
  if (str?.length <= maxLength) {
    return str
  } else {
    return str?.slice(0, maxLength) + '...'
  }
}

export function copyToClipboard(textToCopy: string) {
  const textArea = document.createElement('textarea')
  textArea.value = textToCopy
  document.body.appendChild(textArea)
  textArea.select()

  try {
    document.execCommand('copy')
    successToast({
      title: 'Success',
      message: 'Copied to clipboard',
    })
  } catch (error) {
    console.error('Failed to copy: ', error)
  } finally {
    document.body.removeChild(textArea)
  }
}

export function getDateTimeStringForFilename(date: Date) {
  let hours = date.getHours()
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  const period = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12
  const formattedHours = String(hours).padStart(2, '0')

  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const year = date.getFullYear()

  return `${month}-${day}-${year}_${formattedHours}-${minutes}-${seconds}-${period}`
}

export function sortByObjectProperty<T>(
  array: T[],
  propertyKey: keyof T,
  direction: 'asc' | 'desc',
): T[] {
  return [...array].sort((item1: T, item2: T) => {
    const valueFromItem1: unknown = item1[propertyKey as keyof T]
    const valueFromItem2: unknown = item2[propertyKey as keyof T]

    // If the value is null or undefined we push it to the end
    if (isNil(valueFromItem1)) {
      return 1
    }

    if (isNil(valueFromItem2)) {
      return -1
    }

    if (typeof valueFromItem1 === 'number' && typeof valueFromItem2 === 'number') {
      if (direction === 'asc') {
        return valueFromItem1 - valueFromItem2
      }
      return valueFromItem2 - valueFromItem1
    }

    if (typeof valueFromItem1 === 'string' && typeof valueFromItem2 === 'string') {
      if (direction === 'asc') {
        return valueFromItem1.localeCompare(valueFromItem2)
      }

      return valueFromItem2.localeCompare(valueFromItem1)
    }

    if (valueFromItem1 instanceof Date && valueFromItem2 instanceof Date) {
      if (direction === 'asc') {
        return valueFromItem1.getTime() - valueFromItem2.getTime()
      }

      return valueFromItem2.getTime() - valueFromItem1.getTime()
    }

    if (typeof valueFromItem1 === 'boolean' && typeof valueFromItem2 === 'boolean') {
      if (direction === 'asc') {
        return Number(valueFromItem1) - Number(valueFromItem2)
      }
      return Number(valueFromItem2) - Number(valueFromItem1)
    }

    return 0
  })
}

interface Task {
  performedTask?: string
  technologies?: string
  fromDate?: string
  toDate?: string
  role?: string
}

interface Experience {
  fromDate?: string
  toDate?: string
  institution: string
  tasks?: Task[]
}

export const getDateRageFromExperience = (experience: Experience) => {
  const fromDateMs = experience.tasks?.reduce(
    (acc, task) => (task.fromDate ? Math.min(acc, new Date(task.fromDate).getTime()) : acc),
    Infinity,
  )
  const toDateMs = experience.tasks?.reduce(
    (acc, task) => (!acc || !task.toDate ? null : Math.max(acc, new Date(task.toDate).getTime())),
    -Infinity,
  )

  const fromDate = fromDateMs === Infinity ? null : new Date(fromDateMs).toISOString()
  const toDate =
    toDateMs === -Infinity || toDateMs === null ? null : new Date(toDateMs).toISOString()

  return {
    fromDate: fromDate ? fromDate : experience.fromDate,
    toDate: toDate ? toDate : experience.toDate,
  }
}
