// @ts-nocheck
import React, { useState, useEffect } from 'react'
import compact from 'lodash/compact'
import cn from 'classnames'
import RoundIconButton, { IconsEnum } from './RoundIconButton'
import { useCarousel } from '../contexts/carouselContext'
import setDisableTransfrom from '../actions/setDisableTransform'
import PlusIcon from './Icons/PlusIcon'
import ArrowsOut from './Icons/ArrowsOut'
import GalleryIndicator from './GalleryIndicator'
import TrashIcon from './Icons/TrashIcon'
import cloneDeep from 'lodash/cloneDeep'
import { ChevronLeft, ChevronRight } from 'lucide-react'

export enum Types {
  video = 'video',
  image = 'image',
}

export enum VideoTypes {
  youtube = 'youtube',
  other = 'other',
}

export type Tile = {
  type: Types
  videoType?: VideoTypes
  src: string
}

const ModalGallery = ({
  tile,
  toggleModalOpen,
  previousTile,
  nextTile,
  tilesLength,
}: {
  tile: Tile
  toggleModalOpen: (e?: any) => void
  previousTile: (e?: any) => void
  nextTile: (e?: any) => void
  tilesLength: number
}) => {
  useEffect(() => {
    const handler = (e) => {
      const key = e.keyCode

      if (key === 37) {
        previousTile(e)
      } else if (key === 39) {
        nextTile(e)
      } else if (key === 27) {
        toggleModalOpen()
      }
    }

    document.addEventListener('keydown', handler)

    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [tile])

  return (
    <div
      className='fixed left-0 top-0 z-[100] flex h-full w-full items-center justify-center bg-black bg-opacity-80'
      onClick={toggleModalOpen}
    >
      {tilesLength > 1 && (
        <div className='mr-2 h-12  w-12 rounded-full bg-howdy-primary-green'>
          <RoundIconButton
            icon={IconsEnum.left}
            iconColor='white'
            onClick={(e) => previousTile(e)}
          />
        </div>
      )}

      <div className='w-1/3' onClick={(e) => e.stopPropagation()}>
        {tile.type?.includes(Types.video) ? (
          tile?.videoType === VideoTypes.youtube ? (
            <iframe
              className='w-full'
              style={{
                aspectRatio: ' 16/9',
              }}
              src={`https://www.youtube.com/embed/${tile.src}`}
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            />
          ) : (
            <video
              className='w-full'
              style={{
                aspectRatio: ' 16/9',
              }}
              controls
            >
              <source src={tile.src || URL.createObjectURL(tile)} />
              Your browser does not support HTML video.
            </video>
          )
        ) : (
          <img className='h-full w-full object-cover' src={tile.src || URL.createObjectURL(tile)} />
        )}
      </div>
      {tilesLength > 1 && (
        <div className='ml-2 h-12 w-12 rounded-full bg-howdy-primary-green'>
          <RoundIconButton icon={IconsEnum.right} iconColor='white' onClick={(e) => nextTile(e)} />
        </div>
      )}
    </div>
  )
}

const Gallery = ({
  tiles = [],
  maxIndicators = 5,
  editable = false,
  onChange = () => {},
}: {
  tiles: Tile[]
  maxIndicators: number
  editable: boolean
  onChange?: (elements: [File]) => void
}) => {
  const cloneTiles = () => cloneDeep(compact(tiles))
  const [mainTileIndex, setMainTileIndex] = useState(0)
  const [hoverSides, setHoverSides] = useState({ left: false, right: false })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filesCollections, setFilesCollection] = useState(cloneTiles)
  const carouselContext = useCarousel()
  const tilesLength = filesCollections.length

  useEffect(() => {
    setFilesCollection(cloneTiles)
  }, [tiles])
  useEffect(() => {
    onChange(filesCollections)
  }, [filesCollections])

  const handleTileClick = (index) => {
    if (editable) {
      if (filesCollections[index].avatar) return
      filesCollections[index].ignore = !filesCollections[index].ignore
      setFilesCollection([...filesCollections])
      if (index === mainTileIndex) nextTile()
    } else {
      setMainTileIndex(index)
    }
  }

  const toggleModalOpen = () => {
    if (carouselContext) {
      carouselContext.dispatch(setDisableTransfrom(!isModalOpen))
      setTimeout(() => {
        setIsModalOpen(!isModalOpen)
      }, 10)
    } else {
      setIsModalOpen(!isModalOpen)
    }
  }

  const nextTile = (e, currentId = mainTileIndex) => {
    if (e) {
      e.stopPropagation()
    }
    const possibleNewIndex = currentId >= tilesLength - 1 ? 0 : currentId + 1
    if (filesCollections[possibleNewIndex]?.ignore) {
      nextTile(null, possibleNewIndex)
      return
    }
    setMainTileIndex(possibleNewIndex)
  }

  const previousTile = (e, currentId = mainTileIndex) => {
    if (e) {
      e.stopPropagation()
    }
    const possibleNewIndex = currentId <= 0 ? tilesLength - 1 : currentId - 1
    if (filesCollections[possibleNewIndex]?.ignore) {
      previousTile(null, possibleNewIndex)
      return
    }
    setMainTileIndex(possibleNewIndex)
  }

  const trashOverlay = () => (
    <>
      <div className='absolute h-full w-full bg-howdy-warning-red opacity-80'></div>
      <div className='absolute flex h-full w-full items-center justify-center'>
        <TrashIcon color='white' width={20} height={20} />
      </div>
    </>
  )

  const hoverOverlay = () => (
    <div className='absolute h-full w-full bg-howdy-primary-green opacity-50'></div>
  )

  return (
    <>
      <div className='flex w-full flex-col gap-3'>
        <div className='relative aspect-square shrink-0 cursor-pointer overflow-hidden rounded-lg bg-black'>
          <>
            <div
              onClick={toggleModalOpen}
              className='absolute left-0 top-0 z-10 h-full w-full'
            ></div>
            {tilesLength > 1 && (
              <>
                <div
                  className='absolute bottom-0 left-0 top-0 z-20 flex w-20 items-center md:w-10'
                  onClick={previousTile}
                  onMouseEnter={() => setHoverSides({ left: true, right: false })}
                  onMouseLeave={() => setHoverSides({ left: false, right: false })}
                >
                  <div
                    className={cn(
                      'ml-2 flex h-7 w-7 items-center justify-center rounded-full',
                      `${hoverSides.left ? 'bg-howdy-hover-text' : 'bg-howdy-primary-green'}`,
                    )}
                  >
                    <ChevronLeft color='white' />
                  </div>
                </div>
                <div
                  className='absolute bottom-0 right-0 top-0 z-20 flex w-20 items-center justify-end sm:w-10'
                  onClick={nextTile}
                  onMouseEnter={() => setHoverSides({ left: false, right: true })}
                  onMouseLeave={() => setHoverSides({ left: false, right: false })}
                >
                  <div
                    className={cn(
                      'mr-2 flex h-7 w-7 items-center justify-center rounded-full',
                      `${hoverSides.right ? 'bg-howdy-hover-text' : 'bg-howdy-primary-green'}`,
                    )}
                  >
                    <ChevronRight color='white' />
                  </div>
                </div>

                <div className='absolute bottom-0 flex h-10 w-full justify-center'>
                  <GalleryIndicator
                    itemsCount={tilesLength}
                    currentItem={mainTileIndex}
                    maxIndicators={maxIndicators}
                  />
                </div>
              </>
            )}
            {filesCollections[mainTileIndex]?.type?.includes(Types.video) ? (
              filesCollections[mainTileIndex]?.videoType === VideoTypes.youtube ? (
                <iframe
                  className='h-full w-full'
                  src={`https://www.youtube.com/embed/${filesCollections[mainTileIndex].src}`}
                />
              ) : (
                <video className='h-full w-full'>
                  <source
                    src={
                      filesCollections[mainTileIndex].src ||
                      (filesCollections[mainTileIndex]
                        ? URL.createObjectURL(filesCollections[mainTileIndex])
                        : '')
                    }
                  />
                  Your browser does not support HTML video.
                </video>
              )
            ) : (
              <img
                className='h-full w-full object-cover'
                src={
                  filesCollections[mainTileIndex]?.src ||
                  (filesCollections[mainTileIndex]
                    ? URL.createObjectURL(filesCollections[mainTileIndex])
                    : '')
                }
              />
            )}
            {filesCollections[mainTileIndex]?.type?.includes(Types.video) ? (
              filesCollections[mainTileIndex].videoType !== VideoTypes.youtube && (
                <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center'>
                  <img src='/play-circle.svg' className='h-11 w-11' />
                </div>
              )
            ) : (
              <span className='absolute bottom-0 right-0 z-30' onClick={toggleModalOpen}>
                <ArrowsOut color='white' />
              </span>
            )}
          </>
        </div>
        {(tilesLength > 1 || editable) && (
          <div className='relative  w-full overflow-y-auto'>
            <div className='m-[3px] flex flex-wrap gap-3'>
              {filesCollections.map((tile, index) => {
                if (tile.ignore) return
                return (
                  <div
                    className={cn('relative h-10 w-10 cursor-pointer overflow-hidden rounded', {
                      'outline outline-2 outline-howdy-teal': index === mainTileIndex,
                    })}
                    onClick={() => handleTileClick(index)}
                    key={index}
                  >
                    {tile.type?.includes(Types.video) ? (
                      <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center bg-howdy-light-gray'>
                        <img src='/play-circle.svg' className='h-7 w-7' />
                      </div>
                    ) : (
                      <span
                        className='absolute left-0 top-0 h-full w-full bg-cover bg-center object-cover'
                        style={{ backgroundImage: `url(${tile.src || URL.createObjectURL(tile)})` }}
                      />
                    )}
                    <div className='absolute h-full w-full opacity-0 sm:hover:opacity-100'>
                      {editable && !tile.avatar ? trashOverlay() : hoverOverlay()}
                    </div>
                  </div>
                )
              })}
              {editable && (
                <label
                  className={cn(
                    'relative h-10 w-10 cursor-pointer overflow-hidden rounded bg-howdy-primary-green text-white',
                    'content-center justify-items-center hover:bg-howdy-hover-text',
                  )}
                >
                  <input
                    className='hidden'
                    type='file'
                    name='new_images'
                    multiple
                    onChange={(e) => {
                      const files = e.target.files
                      const images = Array.prototype.filter.call(files, (file) =>
                        file.type.includes('image'),
                      )
                      const videos = Array.prototype.filter.call(files, (file) =>
                        file.type.includes('video'),
                      )
                      setFilesCollection([...filesCollections, ...images, ...videos])
                    }}
                  />
                  <PlusIcon width={30} height={30} />
                </label>
              )}
            </div>
          </div>
        )}
      </div>

      {isModalOpen && (
        <ModalGallery
          tile={filesCollections[mainTileIndex]}
          toggleModalOpen={toggleModalOpen}
          previousTile={previousTile}
          nextTile={nextTile}
          tilesLength={tilesLength}
        />
      )}
    </>
  )
}

export default Gallery
