// @ts-nocheck
import React from 'react'
import LeftChevron from './Icons/LeftChevron'
import RightChevron from './Icons/RightChevron'

const GalleryIndicator = ({
  itemsCount,
  currentItem,
  maxIndicators,
}: {
  itemsCount: number
  currentItem: number
  maxIndicators: number
}) => {
  // Calculate the range of visible indicators
  const halfMaxIndicators = Math.floor(maxIndicators / 2)
  const start = Math.max(0, Math.min(currentItem - halfMaxIndicators, itemsCount - maxIndicators))
  const end = Math.min(itemsCount, start + maxIndicators)

  return (
    <>
      <div className='z-10 flex items-center'>
        {/* Left arrow to indicate more items on the left */}
        <div className='mr-2 h-2 w-2'>
          {start > 0 && <LeftChevron width={8} height={8} color='white' />}
        </div>

        {/* Render the indicators */}
        {[...Array(end - start).keys()].map((index) => {
          const itemIndex = start + index
          return (
            <div
              key={itemIndex}
              className={`mx-1 h-2 w-2 rounded-full ${
                itemIndex === currentItem ? 'bg-white' : 'bg-gray-100 opacity-50'
              }`}
            />
          )
        })}

        {/* Right arrow to indicate more items on the right */}
        <div className='ml-2 h-2 w-2'>
          {end < itemsCount && <RightChevron width={8} height={8} color='white' />}
        </div>
      </div>
      <div
        className='absolute h-full w-full from-black opacity-75'
        style={{ backgroundImage: 'linear-gradient(to top, var(--tw-gradient-stops))' }}
      ></div>
    </>
  )
}

export default GalleryIndicator
