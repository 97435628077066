import { useEffect } from 'react'
import Script from 'next/script'

const AnalyticsScripts = ({ userInfo, accountInfo, pendoId }) => {
  if (typeof window !== 'undefined') {
    window.info = {
      userInfo,
      NEXT_PUBLIC_NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV,
    }
  }

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
      userInfo?.email &&
      !userInfo?.superuser
    ) {
      // eslint-disable-next-line no-undef
      try {
        const full_name = userInfo.name || `${userInfo.firstName} ${userInfo.lastName}`

        pendo.initialize({
          visitor: {
            id: userInfo.email,
            email: userInfo.email,
            full_name,
            role: userInfo.role,
          },

          account: {
            id: accountInfo.id,
            name: accountInfo.name,
          },
        })
        console.log('PENDO started')
      } catch (e) {
        console.error('PENDO ERROR')
        console.error(e)
      }
    }
  }, [userInfo])

  if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
    return (
      <>
        {/* PENDO */}

        {pendoId && (
          <Script
            dangerouslySetInnerHTML={{
              __html: `(function(apiKey){
            (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
              v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                  o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                  y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                  z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
            })('${pendoId}');
        `,
            }}
          />
        )}
      </>
    )
  }

  return null
}

export default AnalyticsScripts
