import React, { createRef, useLayoutEffect, useState } from 'react'

const getPosStyle = (contentDimentions, position, childrenWidth = 0) => {
  const { messagePos, messagePadding } = contentDimentions
  const style = {
    'bottom-left': { top: `35px`, left: `-${messagePos.width - messagePadding * 4}px` },
    bottom: { top: `35px`, left: `-${messagePos.width / 2 - messagePadding * 2}px` },
    'bottom-right': { top: `35px`, left: `-${messagePadding}px` },
    left: { top: `-${messagePos.height / 2 - 5}px`, right: `35px` },
    right: { top: `-${messagePos.height / 2 - 5}px`, left: '35px' },
    'top-left': {
      top: `-${messagePos.height + 20}px`,
      left: `-${messagePos.width - messagePadding * 5}px`,
    },
    top: {
      top: `-${messagePos.height + 20}px`,
      left: `-${messagePos.width / 2 - messagePadding * 2}px`,
    },
    'top-right': { top: `-${messagePos.height + 20}px`, left: `-${messagePadding}px` },
  }
  return style[position]
}

const getArrowStyle = (contentDimentions, position) => {
  const { messagePos } = contentDimentions

  const arrowStyles = {
    'bottom-left': { right: '0px', top: '-10px' },
    bottom: { left: 'calc(50% - 10px)', top: '-10px' },
    'bottom-right': { left: '0px', top: '-10px' },
    left: { right: '-10px', top: `${messagePos.height / 2 - 10}px` },
    right: { left: '-10px', top: `${messagePos.height / 2 - 10}px` },
    'top-left': { right: '5px', top: `${messagePos.height - 15}px` },
    top: { left: 'calc(50% - 10px)', top: `${messagePos.height - 15}px` },
    'top-right': { left: '0px', top: `${messagePos.height - 15}px` },
  }
  return arrowStyles[position]
}

export default function Popover({
  children,
  isVisible,
  position = 'bottom',
  content,
  classes,
  tipClasses,
}) {
  const [contentDimentions, setContentDimentions] = useState<{
    messagePos: DOMRect | object | undefined
    messagePadding: string | null
  }>({ messagePos: {}, messagePadding: '' })
  const childrenRef = createRef<HTMLDivElement>()
  const popoverMessageRef = createRef<HTMLDivElement>()

  useLayoutEffect(() => {
    const messagePos = popoverMessageRef?.current?.getBoundingClientRect()
    const messagePaddingFull =
      popoverMessageRef.current &&
      window.getComputedStyle(popoverMessageRef.current).getPropertyValue('padding')
    const messagePadding =
      messagePaddingFull && messagePaddingFull.substring(0, messagePaddingFull.length - 2)
    setContentDimentions({ messagePos, messagePadding })
  }, [isVisible])

  return (
    <div className='relative inline-block'>
      <span ref={childrenRef}>{children}</span>
      <div
        className={`${
          isVisible ? 'block' : 'hidden'
        } absolute z-50 rounded bg-howdy-primary-green p-[5px] text-white shadow-[4px_4px_8px_0px_rgba(0,0,0,0.10)] ${classes}`}
        ref={popoverMessageRef}
        style={getPosStyle(contentDimentions, position)}
      >
        <div className='relative h-full w-full'>
          <div
            className={`absolute h-4 w-4 rotate-45 bg-howdy-primary-green ${tipClasses}`}
            style={getArrowStyle(contentDimentions, position)}
          />
        </div>
        <div className='relative bg-howdy-primary-green'> {content} </div>
      </div>
    </div>
  )
}
