import React, { ButtonHTMLAttributes } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

interface ButtonStyles {
  primary: string
  secondary: string
  tertiary: string
  primaryRedesign: string
  secondaryRedesign: string
  tertiaryRedesign: string
  underlinedRedesign: string
}

const typesClasses: ButtonStyles = {
  primary: clsx(
    'bg-black text-white',
    'py-[12px] px-[28px]',
    'leading-[28px] text-base',
    'group-hover:bg-howdy-light-green',
    'group-active:bg-howdy-primary-green',
  ),
  secondary: clsx(
    'bg-white text-black border border-black',
    'py-[11px] px-[27px]',
    'leading-[28px]',
    'group-hover:text-white group-hover:bg-howdy-light-green group-hover:border-none group-hover:py-[12px] group-hover:px-[28px]',
    'group-active:text-white group-active:bg-howdy-primary-green group-active:border-none group-active:py-[12px] group-active:px-[28px]',
  ),
  tertiary: clsx(
    'bg-transparent text-black font-bold px-0 py-[12px] decoration-1 underline-offset-2',
    'underline',
    'group-hover:text-howdy-light-green',
    'group-active:text-howdy-primary-green',
  ),
  tertiaryRedesign: clsx(
    'rounded-xl',
    'bg-transparent text-howdy-primary-green',
    'px-6 py-3',
    'group-hover:bg-howdy-hover-bg',
    'group-active:text-howdy-primary-green',
  ),
  primaryRedesign: clsx(
    'rounded-xl',
    'bg-[#449386]',
    'border border-[#449386]',
    'px-6 py-3',
    'leading-[26px]',
    'text-white',
    'hover:bg-howdy-hover-text',
  ),
  secondaryRedesign: clsx(
    'rounded-xl',
    'border border-[#449386]',
    'px-6 py-3',
    'leading-[26px]',
    'text-[#449386]',
    'hover:bg-howdy-hover-text hover:text-white',
  ),
  underlinedRedesign: clsx(
    'bg-transparent text-howdy-primary-green px-0 py-[12px] underline-offset-2',
    'underline',
    'group-hover:text-howdy-light-green',
  ),
}

const disabledStyles = {
  primary: '!bg-howdy-disabled !text-howdy-gray',
  secondary: '!bg-howdy-disabled !text-howdy-gray !border-howdy-gray',
  tertiary: '!text-howdy-gray',
  primaryRedesign: '!bg-howdy-disabled !text-howdy-gray !border-howdy-gray',
  secondaryRedesign: '!bg-howdy-disabled !text-howdy-gray !border-howdy-gray',
  underlinedRedesign: '!text-howdy-gray',
}

export default function Button({
  children,
  style = 'primary',
  type = 'button',
  compact = false,
  disabled,
  onClick,
  fullWidth = false,
  className,
  wrapperClassName,
  childType = 'center',
  dataTestId = 'Button',
}: ButtonHTMLAttributes<any> & {
  style?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'primaryRedesign'
    | 'secondaryRedesign'
    | 'tertiaryRedesign'
    | 'underlinedRedesign'
  fullWidth?: boolean
  className?: string
  wrapperClassName?: string
  compact?: boolean
  childType?: 'center' | 'leftAligned'
  dataTestId?: string
}) {
  const classNames = typesClasses[style as keyof typeof typesClasses]
  const disabledClassNames = disabled ? disabledStyles[style as keyof typeof typesClasses] : ''
  return (
    <div
      className={`${disabled ? '' : 'group'} ${fullWidth ? 'w-full' : ''} ${
        wrapperClassName ? wrapperClassName : ''
      }`}
    >
      <button
        onClick={onClick}
        disabled={disabled}
        type={type}
        data-testid={dataTestId}
        className={twMerge(
          fullWidth && 'w-full',
          'rounded-full',
          'transition-transform',
          'transition-shadow',
          'transition-colors',
          'duration-75',
          'ease-in',
          classNames,
          compact && 'rounded-lg px-4 py-1',
          disabledClassNames,
          className,
        )}
      >
        <div
          className={clsx('flex items-center gap-2', {
            'justify-center': !childType.includes('leftAligned'),
            'justify-start': childType.includes('leftAligned'),
            'font-bold': !style.includes('Redesign'),
          })}
        >
          {children}
        </div>
      </button>
    </div>
  )
}
