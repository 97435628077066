import { FC, useEffect, useState } from 'react'

interface ToogleButtonProps {
  active: boolean
  onToggle: (active: boolean) => Promise<void>
  id: string
  disabled?: boolean
  dataTestId?: string
}

const ToggleButton: FC<ToogleButtonProps> = ({
  active: initialActive,
  onToggle,
  id,
  disabled,
  dataTestId = 'ToggleButton',
}) => {
  const [active, setActive] = useState(initialActive)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(false)
  }, [active])

  useEffect(() => {
    setActive(initialActive)
  }, [initialActive])

  const handleToggle = async () => {
    if (loading) return

    setLoading(true)
    const newActiveStatus = !active

    setActive(newActiveStatus)

    try {
      await onToggle(newActiveStatus)
    } catch (error) {
      setActive(!newActiveStatus)
    } finally {
      setLoading(false)
    }
  }
  return (
    <button
      id={id}
      disabled={disabled}
      data-testid={dataTestId}
      className={`h-[25px] w-[50px] rounded-full border disabled:cursor-not-allowed ${
        loading ? 'border-[#c1c1c1]' : active ? 'border-howdy-primary-green' : 'border-[#c1c1c1]'
      } relative cursor-pointer p-[2px] ${
        active && !loading ? 'bg-howdy-primary-green' : 'bg-howdy-[#DCDCDC]'
      }`}
      onClick={handleToggle}
    >
      <div
        className={`top-[2px] h-[19px] w-[19px] ${
          loading ? 'bg-howdy-disabled-dark' : active ? 'bg-white' : 'bg-howdy-gray'
        } absolute rounded-full transition-all ${
          active ? 'left-[calc(100%_-_21px)]' : 'left-[2px]'
        }`}
      />
    </button>
  )
}

export default ToggleButton
